*::-webkit-scrollbar,
*::-webkit-scrollbar-thumb {
    width: 8px;
    border-radius: 13px;
    background-clip: padding-box;
}

::-webkit-scrollbar-track {
    background-color: transparent;
}

*::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 0 10px #505050;
}